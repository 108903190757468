.card-container {
  display: flex;
  width: 316px;
  height: 347px;
  padding: 26px 36px;
  margin: 0 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  user-select: none;

  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0) 29.69%,
    rgba(255, 255, 255, 0.2) 66.15%,
    rgba(255, 255, 255, 0.3) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
}

.card-container img {
  width: 64px;
  height: 71px;
}

.title-specialties {
  display: flex;
  width: 180px;
  height: 35px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-500, #0018ff);
  text-align: center;

  /* Heading/H4 - Small/Semi Bold */
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.content-specialties {
  display: flex;
  width: 244px;
  height: 131px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-800, #000f3a);
  text-align: center;

  /* Paragraph/P1/Regular */
  font-size: 14px;
  line-height: 17px;
  user-select: none;
}

.button-specialties {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--primary-blue-300, #668eff);

  background: none;
  cursor: pointer;
}

.name-button-specialties {
  color: var(--primary-blue-300, #668eff);

  /* Paragraph/P1/Semi Bold */
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.button-specialties:hover {
  border-color: var(--primary-blue-500, #0018ff);
}

.button-specialties:hover .name-button-specialties {
  color: var(--primary-blue-500, #0018ff);
}

.specialties-container {
  margin: 0 0px 80px;
}

.specialties-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-container {
  width: 100%;
  max-width: 100%;
  margin: 48px auto 0;
  cursor: pointer;
}

.carousel-container .swiper {
  width: 100%;
  height: 100%;
}

.carousel-container .swiper-slide {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container .swiper-wrapper {
  max-height: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.title-container {
  color: var(--primary-blue-500, #0018ff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.subtitle-container {
  color: var(--neutral-900, #171a1d);
  text-align: center;

  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;
}

@media (min-width: 1601px) {
  .carousel-container .swiper-wrapper {
    justify-content: flex-start;
  }
}

@media (max-width: 1151px) {
  .carousel-container {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .carousel-container {
    max-width: 100%;
  }
}

@media (max-width: 599px) {
  .card-container {
    width: 292px;
    height: 343px;
    padding: 24px 24px;
  }
}
