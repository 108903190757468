.contact-container {
  display: flex;
  width: 100vw;
  height: 412px;
  padding: 34px 16px 64px 16px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;

  background: var(--primary-blue-700, #00185d);
}

.contact-title {
  color: var(--primary-blue-50, #e3ebff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.contact-subtitle {
  color: var(--primary-blue-50, #e3ebff);
  text-align: center;
  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;
}

.contact-inputs-container {
  display: flex;
  flex-direction: column;
  gap: 25.5px;
}

.contact-container input {
  height: 56px;
  padding: 16px;
  margin-right: 14px;

  border: 2px solid #eeeeee;
  border-radius: 4px;

  background: none;

  cursor: pointer;

  color: #7ec5ff;
}

.contact-container input::placeholder {
  color: var(--neutral-400, #eeeeee);
  /* Paragraph/P1/Regular */
  font-size: 14px;
  line-height: 17px;
}

.contact-container input:hover {
  border: 2px solid #ccc;
}

.contact-container input:hover::placeholder {
  color: var(--neutral-400, #ccc);
}

.contact-container input:focus {
  outline: none;
  border: 2px solid #7ec5ff;
}

.contact-container input:focus::placeholder {
  color: var(--neutral-400, #7ec5ff);
}

.contact-button-container {
  display: flex;
  justify-content: right;
  align-items: center;

  width: 844px;
}

.contact-button {
  display: flex;
  width: 204px;
  padding: 14px 15px;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--secondary-alt-600, #83c803);

  /* Shadow (SM) */
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  border: none;
}

.contact-button p3 {
  color: var(--secondary-alt-900, #131d00);

  /* Paragraph/P3/Semi Bold */
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
}

@media (max-width: 1151px) {
  .contact-container {
    width: 100%;
    height: fit-content;
  }

  .contact-container .contact-inputs-container {
    width: 100%;
    max-width: 844px;
  }

  .contact-container .contact-inputs-container > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .contact-container .contact-inputs-container > div input {
    margin-bottom: 16px;
    flex: 1;
  }

  .contact-button-container {
    width: 95%;
    max-width: 844px;
  }
}

@media (max-width: 539px) {
  .contact-container .contact-inputs-container > div input {
    width: 100%;
  }
}
