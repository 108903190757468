.background-header {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-blue-500, #0018ff);
  border-bottom: 8px solid var(--secondary-alt-500, #b1fc26);
}

.header__burger {
  display: none;
  background: inherit;
}

.header-container {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 16px 32px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-shrink: 0;

  border: 1px solid rgba(241, 245, 249, 0);
  background: rgba(255, 255, 255, 0);
}

.header-container.header__mobile {
  z-index: 10;
  display: none;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 300ms;
}

.header-container a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.header-container img {
  max-height: 48px;
  height: 36px;
  width: 200px;
}

.header-container a span {
  color: #fff;
  transition: color 300ms;
}

.header-container a:hover span {
  color: var(--secondary-500, #28cfbd);
}

.button-container {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 16px;
}

.button-links {
  background: none;
  border: none;
  cursor: pointer;
}

.name-links {
  color: var(--shades-white, #fff);
  text-shadow: 0px 2px 4px 0px #eeeeee;
  /* Paragraph/P1/Regular */
  font-size: 14px;
  font-family: Poppins;
  line-height: 17px;
}

.banner-container {
  width: 100vw;
  max-width: 1440px;
  height: 680px;
  position: relative;
}

.slogan-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 411px;
  margin-top: 204px;
  margin-left: 38px;
}

.subtitle {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.title {
  color: #fff;
  text-align: right;

  /* Shadow (Border Bottom) */
  text-shadow: 0px 1px 0px 0px #e2e8f0;
  font-size: 60px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: -2.4px;
}

.button-whats {
  display: flex;
  /* width: 263px; */
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--secondary-alt-600, #83c803);

  /* Shadow (LG) */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

  margin: 12px 0px;
  border: none;
  cursor: pointer;

  text-decoration: none;

  transition: background-color 200ms;
}

.button-whats:hover {
  background: var(--secondary-alt-300, #d4fd88);
}

.button-whats:active {
  background: var(--secondary-alt-600, #83c803);
}

.button-whats img {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.button-content {
  color: var(--secondary-alt-900, #131d00);

  /* Paragraph/P3/Semi Bold */
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 21.6px;
}

.rate-container {
  display: flex;
  width: 152px;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}

.rate-container img {
  height: 24px;
  width: 24px;
}

.rate-description {
  color: var(--shades-white, #fff);
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}

@media (max-width: 959px) {
  .header__burger {
    display: block;
    border: none;

    height: 40px;
    width: 40px;
  }

  .header__burger img {
    height: 40px;
    width: 40px;
  }

  .header-container.header__mobile {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .header__mobile__top {
    display: flex;
    flex-flow: row nowrap;
    width: 300px;
  }

  .header-container.header__mobile .header__burger {
    margin-right: 24px;
  }

  .header-container {
    background: rgba(255, 255, 255, 1);
    justify-content: flex-start;
    padding: 16px 24px;
    height: fit-content;
    flex-flow: row wrap;
    transform: scaleY(80px);
    overflow: hidden;
  }

  .header-container .button-container {
    display: none;
  }
  .header-container.header-container.header__mobile .button-container {
    display: flex;
    flex-flow: column wrap;
    flex: 1 1 100%;
  }

  .header-container .button-container a span {
    margin: 12px 0;
  }

  .header-container .button-container a span {
    color: var(--primary-blue-500, #0018ff);
    font-size: 1.25rem;
  }

  .slogan-container {
    width: 95%;
    max-width: 500px;
    margin: 200px auto 0;
    justify-content: center;
    align-items: center;
  }

  .slogan-container h3 {
    text-align: center;
  }
}
