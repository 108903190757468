.treatmentPage-header-container {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 16px 32px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-shrink: 0;

  border-bottom: 4px solid var(--primary-blue-500, #0018ff);
  background: rgb(255, 255, 255);
}

.treatmentPage-header-link-container {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 16px;
}

.treatmentPage-header-link-container a {
  text-decoration: none;
}

.treatmentPage-header-link-container a span {
  color: var(--primary-blue-500, #0018ff);
  transition: color 300ms;
}

.treatmentPage-header-link-container a:hover span {
  color: var(--secondary-500, #28cfbd);
}

.treatmentPage-header-link {
  color: var(--primary-blue-500, #0018ff);
  text-shadow: 0px 2px 4px 0px #eeeeee;

  /* Paragraph/P1/Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}
