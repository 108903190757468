.not-found-page {
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding: 40px;
}

.not-found-page h1 {
  font-size: 24px;
}

.not-found-page p {
  font-size: 16px;
}
