.homeCare-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 72px;
}

.card-homeCare-img {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  padding-right: 74px;

  width: 505px;
  height: 420px;
  flex-shrink: 0;
  border-radius: 30px;
  box-shadow: 0px 10px 15px 0px rgba(77, 106, 146, 0.35);
  background-image: url("../images/pictures/home-care.webp");
  background-color: lightgray;
}

.homeCare-img-subtitle {
  color: #fff;
  text-align: right;
  font-size: 16px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.homeCare-img-title {
  color: var(--platinum, #f3f5f6);
  text-align: right;
  font-size: 60px;
  font-weight: 700;
  line-height: 50px;
}

.homeCare-img-button {
  display: inline-flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--primary-blue-50, #e3ebff);

  background: none;

  margin-top: 9px;

  cursor: pointer;
}

.homeCare-img-button:hover {
  border: 1px solid var(--primary-blue-50, #c1c5cf);
}

.homeCare-img-button:hover .homeCare-img-button-content {
  color: #c1c5cf;
}

.homeCare-img-button-content {
  color: var(--primary-blue-50, #e3ebff);
  /* Caption/Semi Bold */
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

.card-homeCare-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  width: 505px;
}

.card-homeCare-button img {
  height: 24px;
  width: 24px;
}

.homeCare-button-title {
  display: flex;
  width: 400px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-500, #0018ff);
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;
}

.homeCare-button-subtitle {
  color: #000;
  text-align: center;

  /* label */
  font-size: 16px;
  font-weight: 300;
}

.homeCare-button-homeCare {
  margin-top: 5px;
  display: flex;
  width: fit-content;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--secondary-alt-600, #83c803);

  /* Shadow (SM) */
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);

  text-decoration: none;

  cursor: pointer;
  border: none;
  transition: background-color 200ms;
}

.homeCare-button-white-whats {
  color: var(--secondary-alt-900, #131d00);

  /* Paragraph/P3/Semi Bold */
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;

  text-decoration: none;
}

.homeCare-button-homeCare:hover {
  background: var(--secondary-alt-300, #d4fd88);
}

.homeCare-button-homeCare:active {
  background: var(--secondary-alt-600, #83c803);
}

@media (max-width: 1151px) {
  .homeCare-container {
    flex-direction: column;
  }

  .card-homeCare-img {
    width: 95%;
    max-width: 505px;
    padding-right: 0;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
  .card-homeCare-img * {
    width: 95%;
    max-width: 300px;
    text-align: center;
  }
  .card-homeCare-img h4 {
    font-size: 2.5rem;
    width: 100%;
  }
  .card-homeCare-button {
    width: 95%;
    max-width: 505px;
  }

  .homeCare-button-title {
    width: 95%;
    text-align: center;
  }

  .homeCare-button-subtitle {
    width: 100%;
    text-align: center;
  }
}
