.treatment-container {
  position: relative;
  padding: 0;
  background-color: #c7d6ff;
}

.treatment-title {
  color: var(--primary-blue-500, #0018ff);
  text-align: center;
  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.treatment-subtitle {
  color: var(--neutral-900, #171a1d);
  text-align: center;

  /* label */
  font-size: 16px;
  font-weight: 300;
}

.treatment-card-container {
  display: flex;
  width: 400px;
  height: 167px;
  padding: 48px 25px 28px 28px;
  margin: 0 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 20px;
  backdrop-filter: blur(2px);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
}

.treatment-card-container.grow {
  display: flex;
  width: 400px;
  height: 331px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
}

.treatment-card-hidden {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  gap: 12px;
}

.treatment-card-container-title-hidden {
  display: flex;
  width: 294px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.treatment-card-title-hidden {
  color: var(--secondary-50, #e9fbf9);
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: right;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.treatment-card-container-title-hidden img {
  height: 32px;
  width: 32px;
}

.treatment-card-subtitle-hidden {
  display: flex;
  width: 195px;
  height: 18px;
  flex-direction: column;
  flex-shrink: 0;
  text-align: right;

  color: var(--primary-blue-50, #e3ebff);
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  /* Paragraph/P1/Semi Bold */
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  user-select: none;
}

.treatment-card-show {
  display: flex;
  flex-direction: column;
  gap: 8px;

  transition-timing-function: linear;
  transition-duration: 0.5s;
  user-select: none;
}

.treatment-card-title-show {
  color: var(--secondary-300, #a7eee6);
  text-align: start;

  /* Heading/H4 - Small/Semi Bold */
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
  user-select: none;
}

.treatment-card-subtitle-show {
  display: flex;
  width: 276px;
  height: 17px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-50, #e3ebff);

  /* Paragraph/P1/Semi Bold */
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 17px;
  user-select: none;
}

.treatment-card-container {
  height: 167px;
  transition: height 1s ease;
}

.treatment-card-container:hover {
  height: 331px;
}

.treatment-card-content-show {
  color: var(--primary-blue-50, #e3ebff);
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  font-size: 12px;
  font-weight: 400;
  line-height: 17px;

  padding-bottom: 20px;
}

.treatment-card-button-show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 120px;
  height: 33px;
  background-color: transparent;
  border-radius: 20px;

  border: 1px solid #e3ebff;
  border-radius: 8px;

  cursor: pointer;
}

.treatment-card-button-name-show {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #e3ebff;
}

.treatment-card-button-show:hover {
  border-color: #fff;
}

.treatment-card-button-show:hover .treatment-card-button-name-show {
  color: #fff;
}

.treatment-slider {
  width: 100%;
  height: 384px;
  max-width: 100%;
  margin: 0 auto 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.treatment-slider .swiper-slide {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.treatment-slider .swiper-wrapper {
  max-height: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 599px) {
  .treatment-card-container {
    width: 360px;
    height: 167px;
    padding: 48px 20px 28px 16px;
  }

  .treatment-card-container.grow {
    width: 360px;
  }
}

@media (max-width: 359px) {
  .treatment-card-container {
    width: 300px;
    height: 167px;
    padding: 24px 12px 24px 12px;
    margin: 0 4px;
  }
  .treatment-card-container.grow {
    width: 300px;
  }
}
