.modality-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 131px 0px 80px 0px;
}

.modality-title {
  color: var(--primary-blue-500, #0018ff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.modality-subcontainer {
  padding-top: 95px;
  width: 798px;
  flex-shrink: 0;
}

.modality-content-container {
  display: flex;
  gap: 44px;
  padding-bottom: 30px;
}

.modality-content-container img {
  max-height: 64px;
  max-width: 64px;
}

.modality-content-title {
  display: flex;
  width: 632px;
  height: 36px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-500, #0018ff);

  /* Heading/H4 - Small/Semi Bold */
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.modality-content-p {
  color: var(--neutral-900, #171a1d);

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

@media (max-width: 1151px) {
  .modality-container {
    padding: 64px 0 0 0;
    max-width: 95%;
    width: 505px;
    margin: 0 auto 80px;
  }

  .modality-subcontainer {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 56px;
  }

  .modality-title {
    max-width: 95%;
    text-align: left;
  }

  .modality-content-container {
    max-width: 95%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 11px;
  }

  .modality-content-title {
    width: 100%;
    height: fit-content;
  }
}
