.dentist-container {
  width: 100%;
  position: absolute;

  display: flex;
  height: 705px;
  padding: 32px 0px 32px 0px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;

  margin-top: 84px;
}

.title-dentist {
  color: var(--primary-blue-500, #0018ff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.subtitle-dentist {
  color: var(--neutral-900, #171a1d);
  text-align: center;
  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;
}

.container-cards-dentists {
  width: 100%;
  max-width: 100%;
  margin: 48px auto 0;
  margin-top: 23px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  gap: 28px;
}

.container-cards-dentists .swiper {
  width: 100%;
  height: 100%;
}

.container-cards-dentists .swiper-slide {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.container-cards-dentists .swiper-wrapper {
  max-height: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.cards-dentist {
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0.1) 33.33%,
    rgba(255, 255, 255, 0.2) 75.52%,
    rgba(255, 255, 255, 0.5) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  width: 332px;
  margin: 0 20px;
}

.cards-dentist ::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.cards-dentist ::-webkit-scrollbar-thumb {
  background: var(--primary-blue-500, #0018ff);
  border: 0;
  border-radius: 8px;
}

.cards-dentist ::-webkit-scrollbar-track {
  background: #fff;
  border: 0;
  border-radius: 50px;
}

.cards-dentist * {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-blue-500, #0018ff) transparent;
}

.cards-dentist-img {
  height: 303px;
  width: 332px;
  aspect-ratio: 332/303;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.cards-dentists-content {
  display: flex;
  padding: 16px 28px 28px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 0.1) 33.33%,
    rgba(255, 255, 255, 0.2) 75.52%,
    rgba(255, 255, 255, 0.5) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  transition: height 1s ease;
  height: 96px;

  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 320px;
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    max-height: 320px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.cards-names-fixed p {
  margin-top: 8px;
}

.cards-dentists-subcontent {
  opacity: 0;
  animation: fadeOut 1s forwards;
}

.cards-dentists-subcontent.show {
  animation: fadeIn 1s forwards;
}

.cards-dentists-content-hidden {
  padding-top: 32px;
  animation: fadeOut 1s forwards;
  opacity: 0;
}

.cards-dentists-subcontent.show .cards-dentists-content-hidden {
  animation: fadeIn 1s forwards;
}

.cards-dentists-title {
  display: flex;
  width: 276px;
  justify-content: space-between;
  align-items: center;

  color: var(--primary-blue-500, #0018ff);

  /* Heading/H5 - Small/Semi Bold */
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.cards-dentists-title img {
  height: 24px;
  width: 24px;
}

.cards-dentists-subtitle {
  display: flex;
  width: 276px;
  height: 17px;
  flex-direction: column;

  color: var(--primary-blue-300, #668eff);

  /* Paragraph/P1/Regular */
  font-size: 14px;
  font-family: Poppins;
  line-height: 17px;
}

.cards-dentists-subcontent {
  padding: 0px 0px 0px 0px;
}

.cards-dentists-content-text-hidden {
  margin-top: 8px;
  display: flex;
  width: 272px;
  height: 120px;
  overflow-y: auto;
  flex-direction: column;

  color: var(--primary-blue-800, #000f3a);

  /* Caption/Regular */
  font-size: 12px;
  line-height: 14px;
}

.cards-dentists-button-container-hidden {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 16px 0 20px;
}

.cards-dentists-button-hidden {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--primary-blue-300, #668eff);

  background: none;
  cursor: pointer;
}

.cards-dentists-button-name-hidden {
  color: var(--primary-blue-300, #668eff);

  /* Caption/Semi Bold */
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

@media (min-width: 1151px) {
  .container-cards-dentists .swiper-wrapper {
    justify-content: center;
  }
}

@media (max-width: 1151px) {
  .container-cards-dentists {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .container-cards-dentists {
    max-width: 100%;
  }
}

@media (max-width: 599px) {
  .card-container {
    width: 292px;
    height: 343px;
    padding: 24px 24px;
  }
  .cards-dentist {
    height: auto;
    width: 312px;
  }
  .cards-dentist-img {
    height: auto;
    width: 312px;
  }
}
