.careCard-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 52px;

  padding: 0 0px 0px 0px;

  width: 100%;
  flex-shrink: 0;
}

.img-container-careCard {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 50%;
  border-radius: 30px;
}

.content-container-careCard {
  text-align: right;
  width: 50%;
}

.content-container-careCard img {
  width: 25px;
  height: 25px;
}

.img-container-careCard img {
  border-radius: 30px;
  box-shadow: 0px 10px 15px 0px rgba(77, 106, 146, 0.35);
  object-fit: contain;
}

.content-container-left-careCard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.title-careCard {
  display: flex;
  width: 436px;
  flex-direction: column;

  color: var(--primary-blue-500, #0018ff);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-align: right;
}

.label-careCard {
  color: #000;
  text-align: center;
  text-align: right;
  padding: 7px 0px;

  /* label */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.content-careCard {
  display: flex;
  width: 438px;
  height: 108px;
  flex-direction: column;
  color: #000;

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

@media (max-width: 1151px) {
  .careCard-container {
    width: 505px;
    max-width: 95%;
    margin: 0 auto;
    padding: 0 0 0 0;
  }

  .content-container-left-careCard {
    align-items: flex-start;
  }

  .title-careCard {
    text-align: left;
    width: 100%;
    max-width: 436px;
  }

  .content-careCard {
    text-align: left;
    width: 100%;
    max-width: 436px;
    height: fit-content;
  }

  .img-container-careCard,
  .content-container-careCard {
    width: 505px;
    max-width: 95%;
  }

  .img-container-careCard img {
    width: 100%;
  }
}
