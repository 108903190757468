.phrase-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 115px 0px 0px 0px;
  gap: 44px;
}

.left-container {
  width: 420px;
  text-align: right;
}

.right-container {
  width: 420px;
  text-align: left;
}

.title-container-phrase {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.title-phrase {
  width: 400px;

  color: var(--primary-blue-500, #0018ff);

  /* Heading/H3 - Small/Semi Bold */
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.content-phrase {
  display: flex;
  width: 350px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--neutral-900, #171a1d);

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

@media (max-width: 1151px) {
  .phrase-container {
    padding: 32px 0 0 0;
    max-width: 95%;
    width: 505px;
    flex-direction: column;
    margin: 0 auto;
    gap: 22px;
  }

  .left-container {
    text-align: left;
    width: 100%;
  }

  .right-container {
    text-align: left;
    width: 100%;
  }

  .title-container-phrase {
    justify-content: flex-start;
  }

  .content-phrase {
    width: 100%;
  }
}
