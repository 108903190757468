.footer-container {
  display: flex;
  width: 100vw;
  height: 132px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;

  padding: 0 16px;

  background: var(--primary-blue-900, #000923);
}

.footer-background {
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  padding: 31px 16px 16px 16px;
  align-items: center;
}

.footer-container p {
  color: #fff;
  /* Paragraph/P3/Regular */
  font-size: 14px;
  line-height: 20px;
}

.footer-presentation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 95%;
}

.footer-presentation img {
  height: 36px;
  width: 200px;
}

.footer-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-buttons-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.footer-buttons-container button {
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.footer-social-media {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
  gap: 8px;

  width: 100%;
  padding-right: 120px;
}

.footer-social-media p {
  color: var(--neutral-gray-50, #f8fafc);
  text-align: right;

  /* Paragraph/P1/Semi Bold */
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

.footer-social-media img {
  height: 32px;
  width: 32px;
  margin: 0 0 0 16px;
  cursor: pointer;
}

.footer-img {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}

.whatsapp-contact {
  height: 88px;
  width: 88px;
  position: fixed;
  /* translate: 0 -128px; */
  bottom: 20px;
  right: 20px;
  z-index: 9;
}

.whatsapp-contact img {
  height: 88px;
  width: 88px;
}

@media (max-width: 1151px) {
  .whatsapp-contact {
    height: 52px;
    width: 52px;
    transition: translate 300ms;
  }

  .whatsapp-contact img {
    height: 52px;
    width: 52px;
  }
}

@media (max-width: 767px) {
  .footer-container {
    height: fit-content;
  }
  .footer-background {
    flex-direction: column;
  }
  .footer-social-media {
    padding: 20px 0;
    align-items: center;
  }
  .footer-social-media img {
    margin: 8px;
  }
  .footer-social-media .footer-img {
    justify-content: center;
  }
}
