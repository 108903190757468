.information-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 27px;

  margin: 18px 18px 80px;
}

.information-card {
  display: flex;
  width: 320px;
  height: 79px;
  padding: 14px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0) 36.46%,
    rgba(255, 255, 255, 0.15) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(4px);
}

.name-card {
  color: var(--primary-blue-600, #00258b);
  /* Heading/H6 - Small/Semi Bold */
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.32px;
  width: 106px;
}

.name-img-card {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name-img-card img {
  height: 30px;
  width: 30px;
}

.content-container-card {
  display: flex;
  width: 147px;
  flex-direction: column;
  flex-shrink: 0;
}

.content-card {
  color: var(--primary-blue-900, #000923);

  /* Paragraph/P1/Regular */
  font-size: 14px;
  font-family: Poppins;
  line-height: 17px;
}

@media (max-width: 1151px) {
  .information-container {
    flex-direction: column;
    margin: 18px 0px;
    margin: 32px 0px 80px;
  }

  .name-card {
    font-size: 15px;
  }

  .content-card {
    font-size: 13px;
  }

  .information-card {
    padding: 12px 8px;
    width: 312px;
  }
}
