.background-clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-color: #0018ff;
  border-bottom: 8px solid var(--secondary-alt-500, #b1fc26);
}

.background-clients .background-slider {
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 640px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* other guy */
.background-clients .background-slider .image-filter {
  position: absolute;
  z-index: 2;
  background-image: linear-gradient(
    to bottom,
    rgb(0, 24, 255, 1),
    rgb(0, 24, 255, 0.8),
    rgb(0, 24, 255, 0.8),
    rgb(0, 24, 255, 0.8),
    rgb(0, 24, 255, 0.7),
    rgb(0, 24, 255, 0.6),
    rgb(0, 24, 255, 0.5)
  );
  left: 0;
  width: 100%;
  height: 100vw;
  max-height: 640px;
  opacity: 0.4;
}

/* parent */
.background-clients .background-slider .slick-slider {
  position: absolute;
}

.background-clients .background-slider .slick-slider,
.background-clients .background-slider .slick-slider .slick-list {
  height: inherit;
  width: inherit;
}

.background-clients .background-slider .slick-slider div {
  height: inherit;
}

.background-clients .background-slider .slick-slider .clinic_img {
  height: 100vw;
  max-height: 640px;
  object-fit: cover;
}

.background-clients .background-slider .slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-clients .background-slider .slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main_client-container {
  margin-top: 900px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;

  background-image: linear-gradient(
    to bottom,
    #e3ebff 0%,
    #e3ebff 25%,
    #c7d6ff 25%,
    #c7d6ff 100%
  );
}

.client-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 0px 12px 0px;
}

.container-maincard-review-wrapper {
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 0 20px;
  background-image: url("../images/bgs/rectangle-review-bg.webp");
  background-repeat: no-repeat;
  background-position-x: left;
  background-size: 430px;
}

.container-maincard-review {
  display: flex;
  max-width: 960px;
  width: fit-content;
  padding: 12px 24px 26px 24px;
  margin: 24px auto 64px;
  align-items: center;
  gap: 4px;

  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.8) 47.4%,
    rgba(255, 255, 255, 0.6) 100%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
}

.rate-description-black {
  color: var(--neutral-800, #23272c);
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}

.container-card-review {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 24px;
}

.container-card-review-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 297px;
  height: 219px;
}

.container-card-review-right {
  padding: 0px 12px;
  gap: 4px;

  width: 524px;
  height: 198px;
}

.container-card-review-right img {
  height: 20px;
  width: 63px;
  display: flex;
  margin: 0 auto;
  padding: 0px 0px 0px 32px;
}

.container-card-review-right .slick-next {
  display: none !important;
}

.container-card-review-right .slick-prev {
  display: none !important;
}

.card-review-img {
  border: 3px solid var(--secondary-alt-500, #b1fc26);
  border-radius: 72px;

  width: 72px;
  height: 72px;
}

.card-review-name {
  display: flex;
  height: 19px;
  flex-shrink: 0;

  color: var(--neutral-900, #171a1d);
  text-align: center;

  /* header-3 */
  font-size: 16px;
  font-weight: 700;
}

.card-review-subtitle {
  display: flex;
  height: 14px;

  color: var(--secondary-alt-800, #385601);
  text-align: center;

  /* small */
  font-size: 12px;
  font-weight: 300;
}

.card-review-subtitle p {
  margin-right: 5px;
}

.card-review-subtitle img {
  height: 14px;
  width: 17px;
}

.card-review-content {
  display: flex;
  width: 468px;
  height: 129px;
  flex-direction: column;
  justify-content: center;

  color: var(--neutral-800, #23272c);
  text-align: center;

  /* Heading/H6/Bold */
  font-size: 19px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.card-review-content-name {
  display: flex;
  width: 328px;
  height: 17px;
  margin-top: 12px;
  flex-direction: column;
  justify-content: center;

  color: var(--neutral-600, #454d58);
  text-align: center;

  /* Heading/H6 - Small/Semi Bold */
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.32px;
}

.card-review-content-status {
  display: flex;
  width: 246px;
  height: 15px;
  flex-direction: column;
  justify-content: center;

  color: var(--neutral-700, #2e343b);
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

.container-card-review-title {
  color: var(--primary-blue-500, #0018ff);
  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.container-card-review-subtitle {
  color: var(--neutral-900, #171a1d);
  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;
}

.teste {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 0px 0px 0px 32px;
}

.container-feedbacks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  width: 646px;
}

.container-feedbacks img {
  width: 30px;
  height: 19px;
  flex-shrink: 0;
  margin-left: calc(50% - 30px);
}

.container-feedbacks h3 {
  color: var(--primary-blue-50, #e3ebff);
  text-align: center;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  /* Heading/H3 - Small/Semi Bold */
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.container-feedbacks h6 {
  color: var(--primary-blue-200, #9eb7ff);
  text-align: center;

  /* Heading/H6 - Small/Semi Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.32px;
}

.container-feedbacks p {
  color: var(--primary-blue-100, #c7d6ff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.container-feedbacks button {
  display: flex;
  width: 222px;
  height: 50px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 4px;
  background: var(--secondary-alt-600, #83c803);

  /* Shadow (XL) */
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1);

  text-decoration: none;

  border: none;
  cursor: pointer;
}

.container-feedbacks button:hover {
  background: var(--secondary-alt-300, #d4fd88);
}

.container-feedbacks button:active {
  background: var(--secondary-alt-600, #83c803);
}

.container-feedbacks button p {
  color: var(--white, #fff);
  text-align: center;

  /* action */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.card-review-button {
  display: flex;
  width: 215px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--secondary-alt-600, #83c803);

  /* Shadow (XL) */
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1);

  text-decoration: none;

  border: none;
  cursor: pointer;

  transition: background-color 200ms;
}

.card-review-button img {
  height: 18px;
  width: 18px;
}

.card-review-button:hover {
  background: var(--secondary-alt-300, #d4fd88);
}

.card-review-button:active {
  background: var(--secondary-alt-600, #83c803);
}

.card-review-button {
  color: var(--secondary-alt-900, #131d00);

  /* Paragraph/P1/Semi Bold */
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.background-clients-slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-slider .slick-next:before {
  font-size: 0px;
}

.background-slider .slick-prev:before {
  font-size: 0px;
}

@media (max-width: 1151px) {
  .background-clients,
  .background-clients .background-slider .slick-slider,
  .background-clients .background-slider .slick-slider .slick-list {
    height: 100vw;
    max-height: 640px;
  }

  .container-maincard-review-wrapper {
    max-width: 900px;
    background-position-x: calc(100% + 0px);
    background-size: 600px;
  }

  .container-card-review {
    flex-direction: column;
  }

  .container-card-review-subtitle {
    width: 95%;
    text-align: center;
  }

  .container-card-review-right {
    height: fit-content;
  }

  .container-card-review-right img {
    padding: 0;
    margin: 12px auto;
  }

  .card-review-content {
    overflow-y: auto;
    width: inherit;
    height: fit-content;
    padding: 4px 0px;
  }

  .teste {
    padding: 0;
  }
}

@media (max-width: 679px) {
  .container-maincard-review-wrapper {
    background-position-x: calc(100% + 200px);
    background-size: 700px;
  }

  .container-card-review-right {
    width: 420px;
  }
  .container-card-review {
    padding: 12px 0;
  }
}

@media (max-width: 539px) {
  .container-maincard-review-wrapper {
    background-position-x: calc(100% + 300px);
  }
  .container-card-review-right {
    width: 300px;
  }
}

@media (max-width: 399px) {
  .container-maincard-review {
    padding: 12px 12px 26px;
  }
}

@media (max-width: 359px) {
  .container-maincard-review {
    padding: 12px 4px 26px;
  }
}
