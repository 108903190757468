* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.slick-list {
  padding: 0 !important;
}

.dots-divider {
  width: 100%;
  max-height: 10.5px;
  height: 10.5px;
  margin: 0 auto 12px;
}

.dots-divider.dots-divider__white {
  filter: brightness(0) invert(1);
  margin: 0;
}

.swiper-button-prev,
.swiper-button-next {
  height: 72px;
  width: 72px;
  position: absolute;
  top: calc(94.5% - 36px);
  z-index: 2;
  background-image: url("./images/icons/arrow-right.svg");
  background-size: contain;
  background-position: center;
}
.swiper-button-prev {
  left: 0;
  transform: scaleX(-1);
}
.swiper-button-next {
  right: 0;
}

body {
  overflow-x: hidden;
  background-color: #e3ebff;
  /* podre */
  /* background-image: linear-gradient(to bottom, #e3ebff, #c7d6ff); */
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");
