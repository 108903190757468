.sectionParagraph-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 56px;
}

.sectionParagraph-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;

  width: 798px;
  margin-bottom: 30px;
}

.sectionParagraph-title {
  color: var(--primary-blue-500, #0018ff);

  /* Heading/H4 - Small/Semi Bold */
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
  margin-bottom: 10px;
}

.sectionSecondParagraph-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;

  width: 798px;
  margin-bottom: 10px;
}

.sectionParagraph-subcontainer img {
  max-height: 64px;
  max-width: 64px;
  margin: 0 0 auto;
}

.sectionParagraph-second__title {
  color: var(--primary-blue-500, #0018ff);

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
  margin: 0 auto 0 0;
  padding: 0 0 0 108px;
}

.sectionParagraph-content {
  display: flex;
  flex-direction: column;

  color: var(--neutral-900, #171a1d);

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.sectionParagraph-li {
  color: var(--neutral-900, #171a1d);

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;

  margin-left: 20px;
}

ul li::marker {
  font-size: 12px;
  border-radius: 50%;
}

@media (max-width: 1151px) {
  .sectionParagraph-container {
    max-width: 95%;
    width: 505px;
    margin: 0 auto;
  }

  .sectionParagraph-subcontainer,
  .sectionSecondParagraph-subcontainer {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
  }

  .sectionParagraph-second__title {
    padding: 0;
  }
}
