.family-container {
  width: 100%;
  position: relative;

  padding: 160px 2.5% 80px;

  background-image: url("../images/bgs/rectangle-family-bg.webp");
  background-repeat: no-repeat;
  background-size: 1600px;
  background-position-x: calc(50%);
  background-position-y: 60px;
}

.family-container__rectangle-bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgb(199, 214, 255, 1),
    rgb(199, 214, 255, 0.5),
    rgb(199, 214, 255, 0.5),
    rgb(199, 214, 255, 0.5),
    rgb(199, 214, 255, 0.9),
    #e3ebff,
    white
  );
}

.family-container .family__content {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.family-title {
  color: var(--primary-blue-500, #0018ff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.family-subtitle {
  color: var(--neutral-900, #171a1d);
  text-align: center;

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-family: Poppins;
  line-height: 19px;
}

.family-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 28px;
  flex-shrink: 0;
}

.family-img-container img {
  max-width: 100%;
  border-radius: 30px;
  box-shadow: 0px 10px 15px 0px rgba(77, 106, 146, 0.35);
}

.family-img-container p {
  display: flex;
  width: 405px;
  height: 31px;
  flex-direction: column;
  flex-shrink: 0;

  color: #576b75;
  text-align: center;

  /* Paragraph/P1/Regular */
  font-size: 14px;
  line-height: 17px;
}

.family-content-container {
  display: flex;
  width: 937px;
  height: 147px;
  justify-content: center;
  align-items: flex-start;
  gap: 44px;
  flex-shrink: 0;
}

.family-content-container h4 {
  display: flex;
  width: 400px;
  flex-direction: column;

  color: var(--primary-blue-500, #0018ff);
  text-align: right;

  /* Heading/H4/Semi Bold */
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.56px;

  width: 50%;
}

.family-content-container p {
  display: flex;
  width: 400px;
  height: 110px;
  flex-direction: column;

  color: var(--neutral-900, #171a1d);

  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;

  width: 50%;
}

.family-name,
.family-japanese-name {
  display: flex;
  gap: 42px;
  width: 798px;
  flex-shrink: 0;
}

.family-japanese-name img {
  height: 100%;
  width: 64px;
  margin: auto;
}

.family-name h4 {
  display: flex;
  width: 606px;
  height: 36px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-500, #0018ff);

  /* Heading/H4 - Small/Semi Bold */
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.family-name p {
  display: flex;
  width: 690px;
  height: 165px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--neutral-900, #171a1d);

  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;
}

.family-name-meaning h3 {
  display: flex;
  width: 347px;
  height: 56px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-500, #0018ff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.family-japanese-name-meaning h4 {
  display: flex;
  width: 477px;
  height: 36px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-500, #0018ff);

  /* Heading/H4 - Small/Semi Bold */
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.family-japanese-name-meaning p {
  display: flex;
  width: 690px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--neutral-900, #171a1d);
  font-size: 16px;
  font-family: Poppins;
  line-height: 19px;
}

@media (max-width: 1151px) {
  .family-container {
    background-size: 1200px;
  }

  .family-container__rectangle-bg {
  }

  .family-content-container {
    width: 95%;
    max-width: 606px;
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }

  .family-img-container p {
    width: 100%;
    height: fit-content;
  }

  .family-content-container h4 {
    margin: 0;
    width: 100%;
    max-width: 606px;
    text-align: center;
  }
  .family-content-container p {
    height: fit-content;
    width: 100%;
    max-width: 606px;
    text-align: justify;
  }
  .family-name {
    width: 100%;
    max-width: 606px;
    flex-direction: column;
    position: relative;
  }
  .family-name h4 {
    padding: 18px 0 44px 0;
    width: 100%;
    max-width: 606px;
    text-align: center;
  }
  .family-name p {
    width: 100%;
    max-width: 606px;
    text-align: justify;
    height: fit-content;
  }
  .family-name img {
    height: 64px;
    width: 64px;
    position: absolute;
  }

  .family-name,
  .family-japanese-name {
    gap: 12px;
  }

  .family-name-meaning,
  .family-name-meaning h3 {
    width: 100%;
    max-width: 437px;
    height: fit-content;
    margin: 0 auto;
  }
  .family-japanese-name {
    flex-direction: column;
    width: 95%;
    max-width: 606px;
  }
  .family-japanese-name img {
    height: 64px;
    width: 64px;
  }

  .family-japanese-name-meaning,
  .family-japanese-name-meaning p,
  .family-japanese-name-meaning * {
    width: 100%;
    max-width: 606px;
    text-align: justify;
  }
}

@media (max-width: 599px) {
  .family-container {
    background-size: 900px;
  }
  .family-name h4 {
    padding: 6px 0 72px 0;
    width: 100%;
    max-width: 200px;
    text-align: center;
    margin: 0 auto;
  }
}
@media (max-width: 539px) {
  .family-container {
    background-size: 600px;
  }
}
