.explain-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 52px;

  padding: 0 0px 0px 0px;

  width: 100%;
  /* height: 498px; */
  flex-shrink: 0;
}

.img-container-explain {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 50%;
}

.content-container-explain {
  width: 50%;
}

.img-container-explain img {
  width: 505px;
  border-radius: 30px;
  box-shadow: 0px 10px 15px 0px rgba(77, 106, 146, 0.35);
  object-fit: contain;
}

.title-explain {
  display: flex;
  width: 436px;
  flex-direction: column;

  color: var(--primary-blue-500, #0018ff);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}

.label-explain {
  color: #000;
  text-align: center;
  text-align: left;
  padding: 7px 0px;

  /* label */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label-explain h2 {
  /* label */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-explain {
  display: inline-block;
  width: 438px;
  /* height: 198px; */
  color: #000;

  /* Paragraph/P2/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;

  margin-bottom: 30px;
}

.content-explain a {
  width: min-content;
}

@media (max-width: 1151px) {
  .explain-container,
  .careCard-container {
    flex-direction: column;
    padding: 0 0 0 0;
  }

  .content-container-explain {
    max-width: 95%;
    width: 505px;
  }

  .title-explain,
  .content-explain {
    max-width: 438px;
    width: auto;
  }

  .img-container-explain {
    width: auto;
    max-width: 95%;
  }

  .img-container-explain img {
    width: 100%;
  }
}
