.location-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 34px 16px 14px 16px;
  gap: 52px;

  border-top: 8px solid var(--secondary-300, #a7eee6);

  background: linear-gradient(0deg, #0018ff 0%, #0018ff 100%),
    url("../images//pictures/footer.webp"),
    lightgray 43.024px -57.258px / 93.171% 168.976% no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: DARKEN, NORMAL;
}

.location-title {
  color: var(--primary-blue-50, #e3ebff);
  text-align: center;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.location-subtitle {
  color: var(--primary-blue-50, #e3ebff);
  text-align: center;

  /* Paragraph/P2/Regular */
  font-size: 16px;
  line-height: 19px;
}

.location-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 62px;
}

.location-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 13px;

  width: 40%;
}

.location-content-comenttitle {
  color: var(--primary-blue-50, #e3ebff);
  text-align: right;
  font-size: 16px;
  font-style: italic;
}

.location-content-title {
  color: var(--primary-blue-50, #e3ebff);
  text-align: right;

  /* header-1 */
  font-size: 32px;
  font-weight: 700;
}

.location-content-subtitle {
  color: var(--primary-blue-50, #e3ebff);
  text-align: right;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.location-content-phrase {
  display: flex;
  width: 461px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-50, #e3ebff);
  text-align: right;

  /* Heading/H3/Semi Bold */
  font-size: 33px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.66px;
}

.location-content-maincontent {
  display: flex;
  width: 373px;
  height: 200px;
  flex-direction: column;
  flex-shrink: 0;

  color: var(--primary-blue-50, #e3ebff);
  text-align: right;

  /* Paragraph/P1/Regular */
  font-size: 14px;
  font-family: Poppins;
  line-height: 17px;
}

.map-container {
  display: flex;
  width: 463px;
  height: 393px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  gap: 90px;

  padding-bottom: 40px;
}

.contacts p {
  color: var(--neutral-gray-50, #f8fafc);

  /* Paragraph/P1/Semi Bold */
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

.contacts > div > p:first-child {
  font-weight: 700;
}

.contacts-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
}

.contacts-content p3 {
  color: var(--neutral-gray-50, #f8fafc);

  /* Paragraph/P3/Regular */
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 1151px) {
  .location-container {
    padding: 34px 0px 14px 0;
  }

  .location-content-container,
  .location-content {
    flex-direction: column;
    width: 798px;
    max-width: 95%;
  }

  .location-content-phrase,
  .location-content-maincontent {
    max-width: 100%;
  }

  .location-map {
    max-width: 95%;
    align-self: flex-end;
  }

  .location-map #map {
    max-width: 100%;
  }

  .contacts {
    width: 798px;
    max-width: 95%;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 539px) {
  .location-map {
    width: 400px;
  }

  .location-map #map {
    height: 400px;
  }
}

@media (max-width: 414px) {
  .location-map {
    width: 360px;
  }

  .location-map #map {
    height: 360px;
  }
}

@media (max-width: 360px) {
  .location-map {
    width: 312px;
  }

  .location-map #map {
    height: 312px;
  }
}
